.popBox {
  height: calc(100% - 70px);
  margin-bottom: 20px;
  margin-top: 10px;
}

.containerPop {
  height: 100%;
  min-height: 780px;
  text-align: center;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 60px;
}

.popUp {
  position: relative;
  background-clip: padding-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  max-width: 700px;
  padding-bottom: 70px;
}

/* Steps */

.stepView {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 50%;
  align-self: center;
  justify-content: space-around;
}

.stepView div {
  display: flex;
  flex-direction: column;
  position: relative;
}
.step span {
  width: 12px;
  height: 12px;
  background: #a3a3a3;
  align-self: center;
  margin-bottom: 10px;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
}

.step.active span {
  background-color: #eb0a1e;
  box-shadow: 0 0 0 5px #80808021;
}

.step:not(:first-child):before {
  content: "";
  position: absolute;
  width: 200%;
  height: 2px;
  left: -55px;
  top: 5px;
  z-index: 0;
  background-color: #a3a3a3;
}
.step.active::before {
  background-color: #eb0a1e;
}
.step p {
  font-size: 13px;
}
.step.active p {
  color: #eb0a1e;
}

.confirmationStep {
  align-items: center;
}
.confirmationStep img {
  width: 270px;
  margin-bottom: 20px;
  border-radius: 15px;
  aspect-ratio: 8 / 7;
  object-fit: cover;
}

/* Texts */

.popUp p {
  margin: 0 0 20px;
}

.step2 .labelForm {
  text-align: center;
}

.step2 .radioDiv {
  margin-bottom: 20px;
  gap: 10px;
}

/* Form */

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
}

/* Button */

.navStep {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
}

.navStep.planButtons {
  flex-direction: column-reverse;
  gap: 15px;
}

.buttonForm {
  background: #eb0a1e;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 9px 25px;
  outline: none;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.btnnext {
  background-color: #5149391a;
  color: #262627;
  box-shadow: inset 0px 0px 0px 2px #eb0a1e;
}

.backPlan {
  background-color: transparent;
  color: #262627;
}

.loadBoton {
  opacity: 0.6;
  pointer-events: none;
  cursor: progress !important;
}

.formSuccess {
  background: #6aba6d;
  color: #262627;
  opacity: 0.7;
  pointer-events: none;
}

.termscond {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  accent-color: #eb0a1e;
}
.termscond label {
  margin-bottom: 0;
}

.termscond label a {
  color: #eb0a1e;
}

/* Media */

@media (max-width: 650px) {
  /* Media PopUp */
  .popBox {
    height: 100%;
    margin-bottom: 0;
    margin-top: 5px;
  }
  .containerPop {
    min-height: 600px;
    height: auto;
    padding: 20px 15px 10px;
  }
  .popUp {
    width: 100%;
    max-width: 450px;
    padding-bottom: 100px;
  }
  .buttonForm {
    font-size: 17px;
  }

  .popUp .radioCard img {
    min-height: 120px;
    object-fit: cover;
    height: 120px;
  }

  .inputDiv .radioDivCard {
    gap: 20px;
    justify-content: center;
  }

  .inputDiv .radioDivCard > div {
    flex: inherit;
    width: calc(50% - 10px);
  }

  /* Media Step */

  .stepView {
    width: 90%;
  }
  .step:not(:first-child):before {
    width: 160%;
    left: -40px;
    transition: all 0.5s ease;
  }

  .confirmationStep img {
    width: 100%;

    margin-bottom: 20px;
  }

  /* Media Texts */

  .step p {
    font-size: 12px;
  }
  .popUp h2 {
    font-size: 26px;
  }
  .popUp p {
    font-size: 14px;
  }
}
