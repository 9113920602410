.labelInput {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  text-align: left;
}

.labelInput h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
}

.labelInput h3 span {
  font-weight: 600;
}

.numberLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.7;
  margin-left: -30px;
  font-size: 16px;
}

.margTop {
  margin-top: 40px;
}

@media (max-width: 650px) {
  .labelInput h3 {
    font-size: 19px;
  }
  .numberLabel {
    margin-left: 0;
  }
  .labelInput {
    margin-bottom: 10px;
  }
}
